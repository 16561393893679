import { Link, Navigate, useSearchParams } from "react-router-dom";

function ThankYouPurchase() {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("order");

  if (!orderId) {
    return <Navigate to="/" />;
  }

  return (
    <section className="py-100 container">
      <div className="text-center mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card shadow-lg bg-transparent p-4">
              <h1 className="display-4">
                Nous vous confirmons votre achat.
              </h1>

              <p className="lead mt-3">
                Profitez désormais de toutes les fonctionnalités de TaLegacy pour l’écriture de votre histoire !
              </p>

              <div className="mt-4">
                <Link to={"/"} className="aai-btn btn-pill-solid">Retourner aux livres</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


export default ThankYouPurchase;
