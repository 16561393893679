import React, { useRef, useEffect, useState, useContext } from "react";
import {
  getGeneralCategoryLookupsById,
  postGetGenerateCategoriesWithQuestions,
  postGetQuestions,
} from "../../../../core/api/Api";
import { useNavigate, useParams } from "react-router-dom";
import bg from "../../../../assets/img/bg/aai-feature-bg.jpeg";
import img from "../../../../assets/img/features/fi-1.svg";
import AnotherStoryGeneralCategoryOptions from "./AnotherStoryGeneralCategoryOptions";
import CategoryBasedTypes from "./CategoryBasedTypes";
import CategoryLengthTypes from "./CategoryLengthTypes";
import Loading from "../../../common/loading/Loading";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../../core/common-service/CommonService";
import { StoreContext } from "../../../../core/context/StoreContext";
import useIsMobile from "../../../../core/hooks/useMobile";

const GeneralCategoriesOptions = () => {
  let { service_id, general_category_id } = useParams();
  const { isLoggedIn } = useContext(StoreContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [is_another_story, setIsAnotherStory] = useState(false);
  const [is_online_assistant, setIsOnlineAssistant] = useState(false);
  const [book_name, setBookName] = useState("");
  const [loading, setLoading] = useState(false);
  const mounted = useRef(true);

  const [steps, setSteps] = useState([0]);
  const isMobile = useIsMobile();

  const postCreateSession = (
    category_based_type_id,
    category_length_type_id,
    another_story_subject
  ) => {
    setLoading(true);
    if (is_another_story === true && another_story_subject?.length > 0) {
      postGetGenerateCategoriesWithQuestions(
        {
          service_id,
          general_category_id,
          category_based_type_id,
          category_length_type_id,
          another_story_subject,
          book_name,
        },
        isLoggedIn
      )
        .then((res) => {
          setLoading(false);
          if (mounted.current && res?.data?.success) {
            const data = res?.data?.success;
            if (!isLoggedIn) {
              data.checkpoint_data = JSON.parse(data.checkpoint_data);
              localStorage.setItem("trial_session", JSON.stringify(data));
            }
            navigate("/questions/" + data?.id);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      postGetQuestions(
        {
          service_id,
          general_category_id,
          category_based_type_id,
          category_length_type_id,
          another_story_subject: null,
          book_name,
        },
        isLoggedIn
      )
        .then((res) => {
          setLoading(false);
          if (mounted.current && res?.data?.success) {
            const data = res?.data?.success;
            if (!isLoggedIn) {
              data.checkpoint_data = JSON.parse(data.checkpoint_data);
              localStorage.setItem("trial_session", JSON.stringify(data));
            }
            navigate("/questions/" + data?.id);
          }
        })
        .catch((e) => {
          if (mounted.current) setLoading(false);
        });
    }
    window.scrollTo(0, 0);
  };

  const goToQuestions = () => {
    const another_story_subject =
      data.general_categories[0]?.question_categories[0]?.questions[0]?.transcript?.trim() ??
      "";
    const category_length_type_id = data["category_length_types"].find(
      (el) => el?.is_selected
    )?.id;
    const category_based_type_id = data["category_based_types"].find(
      (el) => el?.is_selected
    )?.id;
    if (book_name.length === 0) {
      showToast({ type: "error", message: t("error.book_name_is_required") });
      return;
    }
    if (is_another_story && another_story_subject.length < 100) {
      showToast({
        type: "error",
        message: t("error.description_should_be_more_than_100_char"),
      });
      return;
    }

    if (is_another_story && another_story_subject.length >= 1000) {
      showToast({
        type: "error",
        message: t("error.description_should_be_less_than_1000_char"),
      });
      return;
    }

    if (
      category_based_type_id &&
      category_length_type_id &&
      book_name.length > 0
    ) {
      postCreateSession(
        category_based_type_id,
        category_length_type_id,
        another_story_subject
      );
    } else {
      showToast({
        type: "error",
        message: t("error.please_select_required_boxes"),
      });
    }
  };

  const onLookupClick = (type, item) => () => {
    const ids = [
      "category_based_types-title-id",
      "category_length_types-title-id",
    ];
    var tmp_data = { ...data };

    tmp_data[type].forEach(
      (element) => (element.is_selected = element.id === item.id)
    );

    if (!is_another_story) {
      let _id;

      if (type === "category_based_types") {
        _id = ids.filter((id) => id !== type + "-title-id")[0];
      } else {
        _id = "category_types_submit_container_id";
      }

      setSteps([...steps, 2]);

      document.getElementById(_id).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
    setData(tmp_data);
  };

  const onBookNameChange = (e) => {
    e.preventDefault();
    setBookName(e.target.value);
  };

  const getData = () => {
    setLoading(true);
    getGeneralCategoryLookupsById(general_category_id)
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setIsAnotherStory(
            data?.general_categories?.[0]?.is_another_story ?? false
          );
          setIsOnlineAssistant(
            data?.general_categories?.[0]?.is_online_assistant ?? false
          );
          setData(data);
        }
      })
      .catch((e) => {
        if (mounted.current) setLoading(false);
      });
  };

  const onresult = (index, q_index) => (transcript_str) => {
    setSteps([...steps, 2]);
    let tmp_data = { ...data };
    tmp_data.general_categories[0].question_categories[index].questions[
      q_index
    ].transcript = transcript_str;
    setData(tmp_data);
  };

  const onSubmitAnotherStory = (item, index) => () => {
    onLookupClick("category_based_types", data.category_based_types[0])();
    goToQuestions();
  };

  useEffect(() => {
    mounted.current = true;
    getData();
    return () => {
      mounted.current = false;
    };
  }, []);

  if (data?.length === 0) {
    return <Loading />;
  }

  if (loading) {
    return <Loading text={t("preparing_your_questions") + "..."} />;
  }

  return (
    <section
      className="aai-features py-100"
      // style={{ background: `url(${bg}) no-repeat center center/cover` }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="aai-section-title mb-3 text-center mb-5">
              <h2 className="section-title">
                {t("what_do_you_want_to_write_about")}?
              </h2>
              <p className="section-desc">
                {t("here_you_can_write_about_anything_you_want")}
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mb-5">
          <div className="col-lg-8">
            <div className="aai-form-container">
              <h4 className="section-desc mb-3">{t("book_name")}</h4>
              <div
                className={`d-flex ${isMobile ? "flex-column" : "flex-row"}`}
              >
                <input
                  className="form-control shadow-none"
                  id="book_name"
                  name="book_name"
                  type="text"
                  placeholder={t("enter_your_book_name")}
                  value={book_name}
                  onChange={onBookNameChange}
                  style={{
                    width: isMobile ? "100%" : "80%",
                    marginRight: isMobile ? "0rem" : "1rem",
                    marginBottom: isMobile ? "1rem" : "0rem",
                  }}
                />
                <button
                  disabled={!book_name}
                  onClick={() => {
                    let element = document.querySelector(
                      "#category_based_types-title-id"
                    );
                    if (!element)
                      element = document.querySelector(
                        "#another_story_general_category_options"
                      );
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "start",
                      });
                      setSteps([...steps, 1]);
                    }
                  }}
                  style={{ cursor: book_name ? "pointer" : "not-allowed" }}
                  className="aai-btn btn-pill-solid"
                >
                  {t("continue")}
                </button>
              </div>
            </div>
          </div>
        </div>

        {is_another_story ? (
          <AnotherStoryGeneralCategoryOptions
            data={data}
            onresult={onresult}
            isVisible={steps.includes(1)}
          />
        ) : (
          <CategoryBasedTypes
            data={data}
            img={img}
            onLookupClick={onLookupClick}
            isVisible={steps.includes(1)}
          />
        )}

        <CategoryLengthTypes
          data={data}
          img={img}
          onLookupClick={onLookupClick}
          isVisible={steps.includes(2)}
        />
        {steps.includes(1) && is_another_story ? (
          <div
            className="d-flex"
            style={{ marginTop: "2rem", justifyContent: "center" }}
          >
            <button
              className="aai-btn btn-pill-solid"
              type="submit"
              disabled={loading}
              onClick={onSubmitAnotherStory(
                data?.general_categories?.[0]?.question_categories[0],
                0
              )}
            >
              {t("submit")}
            </button>
          </div>
        ) : steps.includes(1) ? (
          <div
            id="category_types_submit_container_id"
            className="text-center mt-5"
            data-aos="fade-up"
            data-aos-delay="110"
          >
            <button
              className="aai-btn btn-pill-solid"
              disabled={loading}
              onClick={goToQuestions}
            >
              {t("proceed")}
            </button>
          </div>
        ) : null}

        {/* {is_another_story ? (
          <div className="d-flex" style={{marginTop: '2rem', justifyContent: 'center'}}>
            <button
              className="aai-btn btn-pill-solid"
              type="submit"
              disabled={loading}
              onClick={onSubmitAnotherStory(
                data?.general_categories?.[0]?.question_categories[0],
                0
              )}
            >
              {t("submit")}
            </button>
          </div>
        ) : (
          <div
            id="category_types_submit_container_id"
            className="text-center mt-5"
            data-aos="fade-up"
            data-aos-delay="110"
          >
            <button
              className="aai-btn btn-pill-solid"
              disabled={loading}
              onClick={goToQuestions}
            >
              {t("proceed")}
            </button>
          </div>
        )} */}
      </div>
    </section>
  );
};

export default GeneralCategoriesOptions;
