import { Link, useLocation, useNavigate } from "react-router-dom";

export function ThankYouRegister() {
  const navigate = useNavigate();
  const location = useLocation();

  const email = location?.state?.email;

  function handleContinue() {
    return navigate("/confirm-registration", { state: { email } });
  }

  return (
    <section className="py-100 container">
      <div className="text-center mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card shadow-lg bg-transparent p-4">
              <h1 className="display-4">Félicitations pour votre inscription !!</h1>

              <p className="lead mt-3">
                Une question ? Contactez notre service client, nous tâcherons de vous répondre sous 48h.
                C’est urgent ? Notifiez-le dans l’objet et nous prioriserons votre demande.
              </p>

              <p className="text-muted">
                <a href="https://talegacyremembered.com/contact">Nous contacter </a>.
              </p>

              <p className="text-muted">
                <Link to={"/"}>Ecrire un livre </Link>.
              </p>

              <div className="mt-4" onClick={handleContinue}>
                <a href="/" className="aai-btn btn-pill-solid">Continue</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
