import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PAYMENT_GATEWAY_PUBLIC_KEY,
  postCreateGiftCardPaymentForm,
  getOnGiftCardPaymentCallback,
  getTotalUsersCount,
} from "../../../core/api/Api";
import KRGlue from "@lyracom/embedded-form-glue";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../core/common-service/CommonService";
import { saveAs } from "file-saver";
import useIsMobile from "../../../core/hooks/useMobile";

const GiftCardPayment = () => {
  const mounted = useRef(true);

  const { t } = useTranslation();

  const [message, setMessage] = useState("INITIAL");
  const [nbOfUsers, setNbOfUsers] = useState();
  const [paymentStatus, setPaymentStatus] = useState("");
  const [email, setEmail] = useState("");
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { plan_id } = location.state || {};

  const onEmailChange = (e) => {
    const val = e.target.value;
    setEmail(val);
  };

  const setupPaymentForm = async () => {
    const endpoint = "https://static.scelliuspaiement.labanquepostale.fr";
    const publicKey = PAYMENT_GATEWAY_PUBLIC_KEY;

    try {
      postCreateGiftCardPaymentForm({ plan_id, email })
        .then(async (res) => {
          const response = res?.data?.success;
          if (mounted.current && response) {
            const formToken = response?.formToken;
            const orderId = response?.orderId;
            setMessage("PENDING");

            const { KR } = await KRGlue.loadLibrary(
              endpoint,
              publicKey
            ); /* Load the remote library */

            await KR.setFormConfig({
              /* set the minimal configuration */
              formToken,
              "kr-language":
                i18n.language /* to update initialization parameter */,
            });

            await KR.renderElements(
              "#myPaymentForm"
            ); /* Render the payment form into myPaymentForm div */

            await KR.onSubmit(async (res) => {
              const orderStatus = res.clientAnswer.orderStatus;
              if (orderStatus === "PAID") {
                setPaymentStatus(orderStatus);
                getOnGiftCardPaymentCallback(plan_id, orderId, email)
                  .then((res) => {
                    const response = res?.data?.success;
                    if (mounted.current && response) {
                      showToast({
                        type: "success",
                        message: response?.message,
                      });
                      saveAs(response?.url, response?.name);
                      navigate(`/thankyou/purchase?order=${orderId}&plan=${plan_id}`);
                    }
                  })
                  .catch((e) => {});
              }
              return false; // Return false to prevent the redirection
            });
          }
        })
        .catch((e) => {});
    } catch (error) {
      navigate(-1);
      setMessage(error + " (see console for more details)");
    }
  };

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  // useEffect(() => {
  //   const _totalNbUsers = async () => {
  //     const {data} = await getTotalUsersCount()
  //     const {success: {total_users_count}} = data

  //     setNbOfUsers(total_users_count)
  //   }
  //   _totalNbUsers()
  // }, [nbOfUsers])

  const isMobile = useIsMobile();

  return (
    <section
      className="aai-features py-100 payment"
      // style={{ background: `url(${bg}) no-repeat center center/cover` }}
    >
      {/* {nbOfUsers && <h2 className="text-center font-semibold" style={{marginBottom: '1rem', fontSize: '1.3rem'}}>
        {nbOfUsers} {t('people_have_already_written_their_stories_with_taLegacy')}
      </h2>} */}
      <div className="container mt-5">
        {paymentStatus === "PAID" && (
          <div className="container pb-100">
            <div className="row">
              <div className="col-12">
                <h2 className="paid-message-header">
                  Nous vous confirmons votre achat.
                </h2>
                <p className="paid-message-description">
                  Profitez désormais de toutes les fonctionnalités de TaLegacy
                  pour l'écriture de votre histoire!
                </p>
              </div>
            </div>
          </div>
        )}
        {message === "INITIAL" && (
          <div className="row g-3 mb-5 justify-center">
            <div className="col-lg-8">
              <div className="aai-form-container">
                <div className="aai-comment-input">
                  <label className="aai-comment-form-label">
                    {t(
                      "to_receive_your_gift_card_please_enter_your_email_address"
                    )}
                  </label>
                </div>
              </div>

              <div
                className="d-flex align-items-center"
                style={
                  isMobile
                    ? { flexDirection: "column" }
                    : { flexDirection: "row" }
                }
              >
                <div
                  className="aai-form-container w-100"
                  style={
                    isMobile
                      ? { marginBottom: "1rem" }
                      : { marginRight: "3rem" }
                  }
                >
                  <input
                    className="form-control shadow-none"
                    id="email"
                    name="email"
                    type="email"
                    placeholder={t("enter_your_email")}
                    value={email}
                    onChange={onEmailChange}
                  />
                </div>
                <button
                  onClick={setupPaymentForm}
                  type="submit"
                  className="aai-btn btn-pill-solid d-flex justify-content-center"
                  style={isMobile ? { width: "100%" } : { width: "auto" }}
                >
                  {t("continue")}
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="form">
          <div className="d-flex align-items-center justify-content-center">
            <div id="myPaymentForm">
              <div className="kr-smart-form" kr-card-form-expanded="true"></div>
            </div>
            {message === "PAID" && (
              <div data-test="payment-message">{message}</div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GiftCardPayment;
